<template>
  <template v-if="!isLoading">
    <section class="mod-1">
      <div class="top-bg"></div>
      <div class="content">
        <div
          :class="['seal', 'ok-' + (reexamId ? 8 : dataInfo.status)]"
          v-if="dataInfo.status == 8 || dataInfo.status == 9"
        ></div>
        <div class="seal no" v-if="dataInfo.status == 2"></div>
        <div class="top">
          <div v-if="reexamId" class="retake-tag">
            {{ $t("supplement")
            }}<!-- 补 -->
          </div>
          <div class="top-integral">
            <div v-if="useLearnTime && dataInfo.studyIntegral">
              {{ $t("LB_Pro_Integral") }}：<i>{{ dataInfo.studyIntegral }}</i>
              <!-- 积分： -->
            </div>
            <div
              style="margin-left: 30px"
              v-if="useLearnScore && dataInfo.studyScore"
            >
              {{ $t("Pub_Tab_Credit") }}：<i>{{ dataInfo.studyScore }}</i>
              <!-- 学分： -->
            </div>
            <div
              style="margin-left: 30px"
              v-if="hasIntegralMenu && dataInfo.studyTime"
            >
              {{ $t("CM_Period") }}：<i>{{ dataInfo.studyTime }}</i>
              <!-- 学时： -->
            </div>
          </div>
          <!-- 补 -->
          <div class="lable">
            <div class="title">
              <h1>
                <a-tooltip>
                  <template #title>{{ dataInfo.examName }}</template>
                  {{ dataInfo.examName }}
                </a-tooltip>
              </h1>
              <sup v-if="isOverdue == 1">{{
                $t("exam.overdue_can_learn")
              }}</sup>
              <!-- 逾期后可学 -->
            </div>
            <div v-if="mianName" class="main-name">
              {{ $t("exam.test_name") }}：{{ mianName }}
              <!-- 主考名称： -->
            </div>
            <span v-if="isIdp != 1">
              <ClockCircleOutlined />
              {{ dateFormat(dataInfo.startTime) }}
              ~
              {{ dateFormat(dataInfo.endTime) }}
            </span>
          </div>
          <div
            class="result"
            v-if="
              dataInfo.historys.length &&
              dataInfo.status != 1 &&
              dataInfo.status != 0
            "
          >
            {{ $t("exam.to_score") }}：
            <!-- 得分： -->
            <em v-if="dataInfo.status == 8 || dataInfo.status == 9">
              {{ dataInfo.score }}
            </em>
            <em v-if="dataInfo.status == 2" style="color: #ff4c50">
              {{ dataInfo.score }}
            </em>
            <span>{{ $t("exam.score") }}</span>
            <!-- 分 -->
          </div>
        </div>
        <div class="bottom">
          <div class="nums">
            <div class="tj">
              <p>
                <template v-if="dataInfo.limitTime != 0">
                  {{ dataInfo.limitTime / 60 }}
                  <label>{{ $t("CM_Minute") }}</label>
                  <!-- 分钟 -->
                </template>
                <template v-else>{{ $t("CM_Unlimited") }}</template>
                <!-- 不限 -->
              </p>
              <span>{{ $t("LB_Exam_TestTime") }}</span>
              <!-- 考试时长 -->
            </div>
            <div class="tj">
              <p>
                {{ dataInfo.totalScore }}
                <label>{{ $t("exam.score") }}</label>
                <!-- 分 -->
              </p>
              <span>{{ $t("practice.total_score") }}</span>
              <!-- 总分 -->
            </div>
            <div class="tj">
              <p>
                {{ dataInfo.passScore }}
                <label>{{ $t("exam.score") }}</label>
                <!-- 分 -->
              </p>
              <span>{{ $t("LB_Exam_PassCondition") }}</span>
              <!-- 及格条件 -->
            </div>
            <div class="tj">
              <p :class="getTotalNumber() == 0 && 'no-exam'">
                {{ getTotalNumber() }}
                <label v-if="dataInfo.examTimes != -2">{{
                  $t("practice.number_of_times")
                }}</label>
                <!-- 次 -->
              </p>
              <span>{{ $t("exam.last_count") }}</span>
              <!-- 剩余次数 -->
            </div>
          </div>
          <div class="btn">
            <template v-if="canExam()">
              <a-tooltip>
                <template
                  #title
                  v-if="
                    dataInfo.isContinue === 2 &&
                    [8, 9].includes(dataInfo.status)
                  "
                >
                  <!-- 已通过考试，不允许再次参与考试 -->
                  {{ $t("HavePassedTheExamAndAreNotAllowedToTakeItAgain") }}
                </template>
                <a-button
                  type="primary"
                  :disabled="
                    dataInfo.isContinue === 2 &&
                    [8, 9].includes(dataInfo.status)
                  "
                  size="large"
                  @click="btnBeginExam()"
                >
                  {{ $t("LB_Exam_StartExam") }}
                  <!-- 开始考试 -->
                </a-button>
              </a-tooltip>
            </template>
            <template v-else>
              <a-button
                v-if="dataInfo.endTime < currentTime"
                type="primary"
                size="large"
                disabled
              >
                {{ $t("exam.exam_is_over") }}
                <!-- 考试已结束 -->
              </a-button>
              <a-button
                v-else-if="
                  dataInfo.taskType === 2 && dataInfo.startTime > currentTime
                "
                type="primary"
                size="large"
                disabled
              >
                {{ $t("exam.exam_not_started") }}
                <!-- 考试未开始 -->
              </a-button>
              <a-button v-else type="primary" size="large" disabled>
                {{ $t("exam.cannot_exam") }}
                <!-- 暂不可考试 -->
              </a-button>
            </template>
          </div>
        </div>
      </div>
    </section>

    <section class="mod-3" v-if="reexamList.length">
      <div class="content">
        <h5>{{ $t("exam.make_up_exam") }}</h5>
        <!-- 补考安排 -->
        <div class="retake-wrap">
          <div
            class="retake-item"
            v-for="item in reexamList"
            :key="item.reexamId"
          >
            <div class="name">{{ item.reexamName }}</div>
            <div class="start">
              {{ $t("CM_StartTime") }}：{{ dateFormat(item.startTime) }}
            </div>
            <!-- 开始时间： -->
            <div class="end">
              {{ $t("CM_EndTime") }}：{{ dateFormat(item.endTime) }}
            </div>
            <!-- 结束时间： -->
            <div class="action">
              <a-button
                type="link"
                :href="`/exam/detail?id=${item.taskId}&did=${item.detailId}&reexamId=${item.reexamId}&taskType=23`"
              >
                {{ $t("exam.enter_make_ip") }}
                <!-- 进入补考 -->
                <RightOutlined />
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mod-2">
      <div class="content">
        <ul>
          <li>
            <h5>{{ $t("LB_ExamRules") }}</h5>
            <!-- 考试规则 -->
            <div class="card">
              <dl class="rule">
                <dd>
                  <div class="dian">{{ $t("LB_Exam_CutScreenNum") }}：</div>
                  <!-- 切屏次数： -->
                  <div>
                    <span
                      class="switch-screen"
                      v-if="dataInfo.switchNum == -2"
                      >{{ $t("exam.rule_unlimited") }}</span
                    >
                    <!-- 无限次 -->
                    <span
                      class="switch-screen"
                      v-if="dataInfo.switchNum == -1"
                      >{{ $t("exam.cut_screen_tip") }}</span
                    >
                    <!-- 不允许切屏 -->
                    <span class="switch-screen" v-if="dataInfo.switchNum > 0">
                      {{ dataInfo.switchNum
                      }}{{ $t("practice.number_of_times") }}
                      <!-- 次 -->
                    </span>
                    <span class="tips"
                      >{{ $t("exam.note") }}：{{
                        $t("exam.cut_screen_tip_3")
                      }}</span
                    >
                    <!-- 注：超过切屏次数规则系统会强制交卷。 -->
                  </div>
                </dd>
                <dd v-if="dataInfo.switchTime != -2">
                  <div class="dian">{{ $t("exam.screen_cut_time") }}：</div>
                  <!-- 切屏时间： -->
                  <div>
                    <!-- 不限制 -->
                    <span
                      class="switch-screen"
                      v-if="dataInfo.switchTime == -2"
                      >{{ $t("exam.infinitely") }}</span
                    >
                    <span
                      class="switch-screen"
                      v-if="dataInfo.switchTime == 0"
                      >{{ $t("exam.cut_screen_tip") }}</span
                    >
                    <span class="switch-screen" v-if="dataInfo.switchTime > 0">
                      {{ $t("exam.nth_s", [dataInfo.switchTime]) }}
                      <!-- 秒 -->
                    </span>
                    <span class="tips"
                      >{{ $t("exam.note") }}：{{
                        $t("exam.screen_cut_time_tip")
                      }}</span
                    >
                    <!-- 注：超过切屏时间系统会强制交卷。 -->
                  </div>
                </dd>
                <dd>
                  <div class="dian">{{ $t("exam.exam_record_view") }}：</div>
                  <!-- 考试记录查看： -->
                  <div>{{ viewAnswer[dataInfo.viewAnswer] }}</div>
                </dd>
                <dd>
                  <div class="dian">
                    {{ $t("exam.mandatory_submission_rules") }}：
                  </div>
                  <!-- 强制交卷规则： -->
                  <div>
                    <p>
                      （1）{{ $t("exam.mandatory_submission_rules_tip") }}；
                      <!-- （1）进入答题界面开始计时，倒计时结束未自主交卷则系统强制交卷； -->
                    </p>
                    <p>
                      （2）{{ $t("exam.mandatory_submission_rules_tip_2") }}。
                      <!-- （2）进入答题界面开始计时，未交卷退出答题界面，考试用时会继续计时，用时结束系统会强制交卷。 -->
                    </p>
                  </div>
                </dd>
                <dd v-if="dataInfo.certificateId">
                  <div class="dian">{{ $t("exam.study_reward") }}：</div>
                  <!-- 学习奖励： -->
                  <div class="certificate" v-if="dataInfo.templatePath">
                    <img :src="dataInfo.templatePath" />
                    <span class="tips"
                      >{{ $t("exam.note") }}：{{
                        $t("exam.study_reward_tip")
                      }}</span
                    >
                    <!-- 注：考试通过可获得证书 -->
                  </div>
                </dd>
                <dd v-if="dataInfo.rewardMode && hasIntegralMenu">
                  <div class="dian">{{ $t("getScoreWayTip") }}：</div>
                  <!-- 获得积分规则： -->
                  <div>
                    <p v-if="dataInfo.rewardMode === 1">
                      {{
                        dataInfo.integralMaxTimes > 0
                          ? $t("ScoreTimeWayTip", {
                              scores: dataInfo.studyIntegral,
                              times: dataInfo.integralMaxTimes,
                            })
                          : $t("ScoreTimeWayTip2", {
                              scores: dataInfo.studyIntegral,
                            })
                      }}。
                      <!-- 按合计次数给分 通过一次获得 2 积分，合计最多3次可获得积分 -->
                    </p>
                    <p v-else-if="dataInfo.rewardMode === 2">
                      {{
                        dataInfo.integralMaxTimes > 0
                          ? $t("ScoreDayWayTip", {
                              scores: dataInfo.studyIntegral,
                              times: dataInfo.integralMaxTimes,
                            })
                          : $t("ScoreDayWayTip2", {
                              scores: dataInfo.studyIntegral,
                            })
                      }}。
                      <!-- 按每日次数给分 每日通过一次获得 2 积 分，每日最多1 次可获得积分 -->
                    </p>
                    <p v-else-if="dataInfo.rewardMode === 3">
                      {{
                        $t("ScoreNumberWayTip", {
                          scores: dataInfo.studyIntegral,
                        })
                      }}。
                      <!--按答对个数给分 每答对一题获得{scores}积分-->
                    </p>
                  </div>
                </dd>
                <!-- 定位验证通过才能参加考试 -->
                <!-- <dd v-if="dataInfo.locationLng">
                  <div class="dian">{{ $t("VerificationPassesBeforeYouCanTakeTheExam", { type: $t('Location') }) }}</div>
                </dd> -->
                <dd v-if="dataInfo.ipControl === 1">
                  <!-- IP验证通过才能参加考试 -->
                  <div class="dian">
                    {{
                      $t("VerificationPassesBeforeYouCanTakeTheExam", {
                        type: "IP",
                      })
                    }}
                  </div>
                </dd>
                <dd v-if="faceSet && (faceSet.start || faceSet.center)">
                  <!-- IP验证通过才能参加考试 -->
                  <div class="dian">
                    <span v-if="faceSet.start">
                      <!-- 人脸识别通过才能参加考试 -->
                      {{
                        $t("VerificationPassesBeforeYouCanTakeTheExam", {
                          type: $t("XB_FacialIdentification"),
                        })
                      }}；
                    </span>
                    <span v-if="faceSet.center">
                      <!-- 考试中人脸识别不通过系统会强制交卷 -->
                      {{ $t("ExamRuleFaceTip") }}
                    </span>
                  </div>
                </dd>
                <dd>
                  <!-- 通过后是否允许参加考试 -->
                  <div class="dian">
                    {{ $t("PermissionToSitForExamsAfterPassing") }}：
                    <!-- 允许/不允许 -->
                    {{
                      $t(
                        dataInfo.isContinue === 1
                          ? "Permissible"
                          : "Impermissible"
                      )
                    }}。
                  </div>
                </dd>
              </dl>
            </div>
          </li>
          <li>
            <h5>{{ $t("exam.exam_description") }}</h5>
            <!-- 考试说明 -->
            <div
              v-if="dataInfo.intro"
              class="card"
              v-html="dataInfo.intro"
              style="white-space: pre-line; word-break: break-all"
            ></div>
            <a-empty v-else :description="$t('exam.no_description')" />
            <!-- 暂无说明 -->
          </li>
          <li>
            <h5>{{ $t("XB_ExamRecord") }}</h5>
            <!-- 考试记录 -->
            <div class="card">
              <div class="history" v-if="dataInfo.historys.length">
                <div
                  class="item"
                  :class="{ gray: !item.toInfo }"
                  v-for="(item, index) in dataInfo.historys"
                  :key="index"
                  @click="GetResultDetailUrl(item)"
                >
                  <div class="info">
                    <p>
                      {{ $t("Pub_Lab_ExamTime") }}：{{
                        dateFormat(item.startTime)
                      }}
                    </p>
                    <!-- 考试时间： -->
                    <p>
                      {{ $t("exam.submit_time") }}：{{
                        dateFormat(item.submitTime)
                      }}
                    </p>
                    <!-- 交卷时间： -->
                    <p>
                      {{ $t("exam.use_time") }}：
                      <!-- 考试用时： -->
                      <span>
                        {{
                          item.useTime > 0
                            ? formatSeconds(item.useTime)
                            : $t("exam.nth_s", [0])
                        }}
                      </span>
                    </p>
                    <div
                      v-if="item.submitType !== 7"
                      class="status"
                      :class="'status-' + item.status"
                    >
                      {{ examStatus[item.status] }}
                    </div>
                    <div
                      v-if="item.submitType !== 7"
                      class="score"
                      :class="[8, 9].includes(item.status) && 'pass'"
                    >
                      {{ item.status != 1 ? item.score : "-" }}
                      <span>{{ $t("exam.score") }}</span>
                      <!-- 分 -->
                    </div>
                  </div>
                  <div
                    v-if="isSignature && item.signImage == ''"
                    class="signature-icon"
                    title="补签"
                  ></div>
                  <!-- 补签 -->
                  <!-- 无效 -->
                  <div class="not-valid" v-if="item.submitType === 7"></div>
                </div>
              </div>
              <a-empty v-else />
            </div>
          </li>
        </ul>
      </div>
    </section>
    <!-- 签名 -->
    <signatureModal ref="signatureModalRef" @complete="completeSignature" />
    <!-- 考试密码 -->
    <passwordModal ref="passwordModalRef" @complete="btnBeginExam" />
    <!-- 定位 -->
    <!-- <baiduMap v-if="dataInfo.locationLng" ref="baiduMapRef" /> -->
    <!-- 人脸识别 -->
    <faceMatch
      ref="faceMatchRef"
      :errAutoBack="false"
      :taskType="1"
      @on-result="handleFace"
    />
  </template>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { getTaskDetail } from "@/api/project";
import { plusDetail } from "@/api/course";
import { getRetakeDetail, getRetakeList, getIpIsInRange } from "@/api/exam";
import { getCurrentTime } from "@/api/user";
import { dateFormat, formatSeconds } from "@/utils/tools";
import { Modal } from "ant-design-vue";
import {
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  ref,
  watch,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import signatureModal from "./signatureModal.vue";
import passwordModal from "./passwordModal.vue";
// import baiduMap from "@/components/map/baiduMap.vue";
import faceMatch from "@/components/faceCapture/match.vue";
import ls from "@/utils/local-storage";
export default {
  props: {
    taskIdP: {
      type: Number,
      default: 0,
    },
    didP: {
      type: Number,
      default: 0,
    },
    source: {
      type: String,
      default: "",
    },
  },
  components: {
    signatureModal,
    passwordModal,
    // baiduMap,
    faceMatch,
  },
  setup(props) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const config = computed(() => store.getters.platformConfig);
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      id: props.taskIdP || parseInt(route.query.id || route.query.taskId || 0),
      did:
        props.didP ||
        parseInt(route.query.did || route.query.coursePlusId || 0),
      courseId: parseInt(route.query.courseId || 0),
      reexamId: parseInt(route.query.reexamId || 0),
      planId: parseInt(route.query.planId || 0),
      isIdp: parseInt(route.query.isIdp || 0),
      openFace: false, //项目包是否配置人脸采集
      isOverdue: 0, //1:逾期后可学
      dataInfo: {},
      showInfoCont: false,
      showDateCont: false,
      ExamSetting: {},
      isLoading: true,
      currentTime: Date.parse(new Date()) / 1000, //当前时间的时间戳(秒)
      examStatus: {
        1: $t("XB_Exam_PendingApproval"), // 待批阅
        2: $t("XB_Unthread"), // 不通过
        8: $t("CM_Pass"), // 通过
        9: $t("CM_Pass"), // 通过
      },
      viewAnswer: {
        1: $t("exam.exam_record_view_tip"), // 交卷后可查看答卷
        2: $t("exam.exam_record_view_tip_2"), // 及格后可查看答卷
        3: $t("exam.exam_record_view_tip_3"), // 考试结束后可查看答卷
        4: $t("exam.exam_record_view_tip_4"), // 不允许查看查看答卷
      },
      isSignature: false,
      mianName: "", // 主考名称
      reexamList: [], // 补考列表
      useLearnTime: false,
      useLearnScore: false,
      hasIntegralMenu: false,
      faceSet: null,
      faceStartResult: null, // 考试前人脸识别结果
    });

    watch(
      () => store.getters.companyInfo,
      (val) => {
        if (val.useLearnTime == 1) state.useLearnTime = true;
        if (val.useLearnScore == 1) state.useLearnScore = true;
        if (val.menu) {
          if (val.menu.includes("integral")) state.hasIntegralMenu = true;
        }
      },
      { immediate: true, deep: true }
    );

    const canExam = () => {
      let d = state.dataInfo;
      let flag = false;
      if (d.status != 1 && !d.startTime) {
        if (
          d.examTimes == -2 ||
          (d.examTimes == -1 && d.myTimes <= 0) ||
          (d.examTimes > 0 && d.myTimes < d.examTimes)
        ) {
          flag = true;
        }
        return flag;
      }
      if (d.status != 1 && d.startTime <= state.currentTime) {
        if (state.isOverdue == 1) {
          if (
            d.examTimes == -2 ||
            (d.examTimes == -1 && d.myTimes <= 0) ||
            (d.examTimes > 0 && d.myTimes < d.examTimes)
          ) {
            flag = true;
          }
        } else {
          if (d.endTime >= state.currentTime) {
            if (
              d.examTimes == -2 ||
              (d.examTimes == -1 && d.myTimes <= 0) ||
              (d.examTimes > 0 && d.myTimes < d.examTimes)
            ) {
              flag = true;
            }
          }
        }
      }
      return flag;
    };
    const courseCanxam = () => {
      let d = state.dataInfo;
      let flag = false;
      if (d.status != 1) {
        if (
          d.examTimes == -2 ||
          (d.examTimes == -1 && d.myTimes <= 0) ||
          (d.examTimes > 0 && d.myTimes < d.examTimes)
        ) {
          flag = true;
        }
      }
      return flag;
    };

    const getDetail = async () => {
      getCurrentTime().then((res) => {
        if (res.ret == 0) {
          state.currentTime = res.data;
        }
      });
      let method = getTaskDetail;

      if (route.query.taskType == 23) {
        method = getRetakeDetail;
      }
      // 课程考试
      let res = null;
      if (state.courseId) {
        let params = { taskId: state.id, courseId: state.courseId };
        if (state.planId) params.planId = state.planId;
        res = await plusDetail(params);
      } else {
        res = await method({
          id: state.id,
          did: state.did,
          reexamId: state.reexamId,
        });
      }
      if (res.ret === 0) {
        let d = res.data;
        if (route.query.taskType == 23) {
          state.mianName = d.examName || "";
          state.did = d.detailId;
        }
        let exam = d.exam;
        exam.historys = exam.historys || [];
        for (let i = 0; i < exam.historys.length; i++) {
          if (
            exam.viewAnswer == 1 ||
            (exam.viewAnswer == 2 && (exam.status == 8 || exam.status == 9)) ||
            (exam.viewAnswer == 3 && state.currentTime > exam.endTime)
          ) {
            exam.historys[i].toInfo = true;
          }
        }
        state.dataInfo = exam;
        state.isOverdue = d.isOverdue;
        state.dataInfo.taskType = d.taskType;
        state.isLoading = false;
        if (state.dataInfo.myTimes > 0) {
          // 1等待批阅 2不通过 8通过 9补考通过
          state.dataInfo.historys.map((item) => {
            if (item.status == 1) {
              state.dataInfo.status = 1;
            }
          });
        }

        if (state.courseId) {
          state.isSignature = state.dataInfo.openSign === 3;
        } else {
          // 是否可以补签
          state.isSignature =
            state.dataInfo.openSign === 3 && state.currentTime <= exam.endTime;
          d.taskType != 2 && getList();
        }

        // 人脸识别
        state.openFace = d.exam.openFace == 1 ? true : false;
        if (state.openFace) {
          state.faceSet = d.exam.openFaceverify
            ? JSON.parse(d.exam.openFaceverify)
            : "";
        }
      } else {
        const status = res.msg.includes("未授权") ? "0" : res.data.status;
        const msg = {
          9: $t("has_been_deleted"), // 已删除
          5: $t("unpublished"), // 已取消发布
          1: $t("not_exist"), // 不存在
          0: $t("no_permission"), // 没有参与权限
        };
        //必须按顺序解锁学习
        if (res.data && res.data.learnOrder) {
          router.replace({
            path: `/project/detail`,
            query: { id: state.id },
          });
        } else if (res.data && res.data.taskType === 2) {
          router.replace({
            path: `/error`,
            query: {
              title: $t("exam.daily_err_tip", [msg[status]]),
              // 抱歉，您访问的每日一练
            },
          });
        } else {
          router.replace({
            path: `/error`,
            query: {
              title: $t("exam.exam_err_tip", [msg[status]]),
              // 抱歉，您访问的考试
            },
          });
        }
      }
    };
    getDetail();

    // 补考列表
    const getList = () => {
      if (state.reexamId) return;
      const param = {
        taskId: state.id,
        detailId: state.did,
        page: 1,
        pageSize: 30,
      };
      getRetakeList(param).then((res) => {
        if (res.ret === 0) {
          state.reexamList = res.data.list || [];
        }
      });
    };

    const btnBeginExam = async (flag = false) => {
      if (state.faceSet && !antiCheatingPass.face) {
        if (state.faceSet.start) {
          faceMatchRef.value.show(1);
          return false;
        } else if (state.faceSet.center) {
          // 检测人脸是否正常
          const isFace = await faceMatchRef.value.checkFace();
          if (!isFace) return false;
        }
      }
      // if (state.dataInfo.locationLng && !antiCheatingPass.location) {
      //   handleLocation();
      //   return false;
      // }
      if (state.dataInfo.ipControl == 1 && !antiCheatingPass.ip) {
        handleIp();
        return false;
      }
      if (state.dataInfo.password && !flag) {
        return passwordModalRef.value.show(state.dataInfo.password);
      }
      // let url = `/exam/paper?taskId=${state.id}&detailId=${state.did}&planId=${state.planId}`;
      // window.open(
      //   url,
      //   "考试答题",
      //   `width=${screen.availWidth},height=${
      //     screen.availHeight - 30
      //   },top=0,left=0`
      // );
      let query = {
        taskId: state.id,
        detailId: state.did,
        source: props.source,
        switchNum: state.dataInfo.switchNum,
        isIdp: state.isIdp,
      };
      if (route.query.taskType == 23) {
        query.reexamId = state.reexamId;
        query.taskType = route.query.taskType;
      }
      if (state.courseId) {
        query.courseId = state.courseId;
      }
      if (state.planId) {
        query.planId = state.planId;
      }
      if (state.faceStartResult) {
        // 缓存人脸识别结果 进入答题界面后 生成id后传给后台
        ls.set("examFaceStartRes", state.faceStartResult);
        state.faceStartResult = null;
      }
      router.push({
        path: "/exam/paper",
        query: query,
      });
    };
    const signatureModalRef = ref(null);
    //查看答案 1交卷后 2及格后 3考试结束后 4不允许查看
    const GetResultDetailUrl = (item) => {
      if (state.isSignature && item.signImage == "") {
        return signatureModalRef.value.show(item.submitId, state.courseId);
      }
      if (item.toInfo) {
        router.push({
          path: "/exam/record",
          query: {
            taskId: state.id,
            detailId: state.did,
            paperId: state.dataInfo.paperId,
            submitId: item.submitId,
            ddtab: true,
            reexamId: state.reexamId,
            courseId: state.courseId,
          },
        });
      } else {
        let msg = $t("XB_Exam_AnswerCannotBeViewed");
        // 不可查看答卷
        state.dataInfo.viewAnswer === 2 &&
          (msg = $t("exam.view_exam_sheet", 1));
        // 考试及格才能查看答卷
        state.dataInfo.viewAnswer === 3 &&
          (msg = $t("exam.view_exam_sheet", 2));
        // 考试结束才能查看答卷
        proxy.$message.info(msg);
      }
    };
    // 获取考试总次数  -1不允许重考 1代表重考1次 -2代表不限制考试次数
    const getTotalNumber = () => {
      if (state.dataInfo.examTimes == -2) {
        return $t("CM_Unlimited");
        // 不限
      } else if (state.dataInfo.examTimes == -1) {
        return state.dataInfo.myTimes >= 1 ? 0 : 1;
      } else {
        return parseInt(state.dataInfo.examTimes) - state.dataInfo.myTimes;
      }
    };

    const completeSignature = () => {
      getDetail();
    };
    const passwordModalRef = ref(null);
    /**
     * 防作弊验证
     * 1.人脸
     * 2.定位
     * 3.IP
     */
    const antiCheatingPass = reactive({
      face: false,
      location: false,
      ip: false,
    });
    const handleIp = async () => {
      let params = {};
      // taskType 1任务 2课程
      if (state.courseId) {
        params = {
          taskType: 2,
          courseId: state.courseId,
          coursePlusId: state.did,
        };
      } else {
        params = {
          taskType: 1,
          taskId: state.id,
          detailId: state.did,
        };
      }
      // IP验证中
      proxy.$message.loading(proxy.$t("Validating", { type: "IP" }), 0);
      const res = await getIpIsInRange(params);
      proxy.$message.destroy();
      if (res.ret === 0) {
        if (res.data.code) {
          proxy.$message
            .success($t("VerificationPassed", { type: "IP" }))
            .then(() => {
              antiCheatingPass.ip = true;
              btnBeginExam();
            });
        } else {
          // 学员当前IP不在考试指定IP范围内，无法参与考试
          proxy.$message.error($t("IPErrorTip"));
        }
      } else {
        proxy.$message.error(res.msg);
      }
    };
    const baiduMapRef = ref(null);
    const handleLocation = () => {
      baiduMapRef.value.getMyToLocationDistance(
        state.dataInfo.locationLng,
        state.dataInfo.locationLat,
        cbLocation
      );
    };
    const cbLocation = (s) => {
      proxy.$message.destroy();
      console.log(s, "-ss--");
      if (s <= state.dataInfo.rangePath) {
        proxy.$message
          .success($t("VerificationPassed", { type: $t("Location") }))
          .then(() => {
            antiCheatingPass.location = true;
            btnBeginExam();
          });
      } else {
        // 学员当前定位不在考试设定的地址范围内，无法参与考试!
        proxy.$message.error($t("LocationErrorTip"));
      }
    };
    const faceMatchRef = ref(null);
    const handleFace = (data) => {
      state.faceStartResult = data;
      if (data.code == 1) {
        proxy.$message
          .success(
            $t("VerificationPassed", { type: $t("XB_FacialIdentification") })
          )
          .then(() => {
            antiCheatingPass.face = true;
            btnBeginExam();
          });
      } else {
        // 人脸识别不通过，请确定是否是本人或者重新采集照片
        Modal.warning({
          title: () => $t("XB_WarmTip"),
          // 温馨提示
          content: () =>
            $t(
              config.value.customerCode === "dfzx"
                ? "FaceMatchErrorTip3"
                : "FaceMatchErrorTip"
            ),
          okText: () => $t("CM_Confirm"),
          keyboard: false,
          centered: true,
          onOk: () => {
            router.go(-1);
          },
        });
      }
    };

    onMounted(() => {
      Modal.destroyAll();
    });

    return {
      dateFormat,
      formatSeconds,
      ...toRefs(state),
      canExam,
      getTotalNumber,
      btnBeginExam,
      GetResultDetailUrl,
      completeSignature,
      signatureModalRef,
      courseCanxam,
      passwordModalRef,
      baiduMapRef,
      faceMatchRef,
      handleFace,
    };
  },
};
</script>

<style lang="less" scoped>
.mod-1 {
  position: relative;
  padding-top: 100px;
  .top-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 300px;
    background: linear-gradient(90deg, #272f46 0%, rgba(39, 47, 70, 0.8) 100%);
  }
  .content {
    .mixinWrap();
    background-color: #fff;
    padding: 40px 35px;
    position: relative;
    border-radius: 6px;
    .seal {
      position: absolute;
      top: 0;
      right: 0;
      width: 160px;
      height: 160px;
      background-size: cover;
      &.ok-8 {
        background-image: url("~@/assets/image/exam-ok.png");
      }
      &.ok-9 {
        background-image: url("~@/assets/image/exam-ok-9.png");
      }
      &.no {
        background-image: url("~@/assets/image/exam-no.png");
      }
    }
    .top {
      // height: 160px;
      .mixinFlex(space-between; center);
      // position: relative;
      overflow: hidden;
      &-integral {
        position: absolute;
        top: 30px;
        right: 35px;
        font-size: 16px;
        display: flex;
      }
      .retake-tag {
        position: absolute;
        top: 0;
        left: 0;
        padding: 2px 8px 8px;
        background-color: orange;
        font-size: 18px;
        color: #fff;
        border-radius: 6px 0 30px 0;
      }
      .lable {
        .title {
          .mixinFlex(flex-start);
        }
        h1 {
          max-width: 750px;
          font-size: 32px;
          .mixinEllipsis(48px);
          margin-bottom: 10px;
          font-weight: 600;
        }
        sup {
          font-weight: normal;
          font-size: 14px;
          color: var(--theme);
          padding: 14px 0 0 2px;
        }
        span {
          font-size: 20px;
          color: #666;
        }
        .main-name {
          margin: 5px 0 10px;
          font-size: 16px;
          color: #999;
        }
      }
      .result {
        font-size: 16px;
        em {
          color: #00cca3;
          font-size: 40px;
          font-weight: 700;
        }
        span {
          padding-left: 10px;
        }
      }
    }
    .bottom {
      margin-top: 30px;
      padding-top: 20px;
      .mixinFlex(space-between; center);
      border-top: 1px solid #e6e6e6;
      .nums {
        .mixinFlex(space-between; center);
        width: 700px;
        .tj {
          flex: 1;
          position: relative;
          text-align: center;
          &:nth-child(n + 2)::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 2px;
            height: 10px;
            background-color: #333;
          }
          p {
            font-size: 36px;
            font-weight: 700;
            color: var(--theme);
            margin: 0;
            letter-spacing: -0.04em;
            label {
              padding-left: 10px;
              font-size: 12px;
              color: #999;
            }
          }
          .no-exam {
            color: #999;
          }
        }
      }
      .btn {
        align-self: flex-end;
        ::v-deep(.ant-btn) {
          width: 160px;
          height: 50px;
          font-size: 22px;
          border-radius: 6px;
        }
      }
    }
  }
}

.mod-2 {
  padding: 20px 0 80px;
  .content {
    .mixinWrap();
    background: #fff;
    padding: 0 28px 20px;
    border-radius: 6px;
    ::v-deep(.ant-tabs-nav .ant-tabs-tab) {
      font-size: 20px;
      padding: 16px;
    }
    ul {
      li {
        h5 {
          position: relative;
          margin: 0;
          padding: 20px 12px;
          font-size: 20px;
          font-weight: 550;
          color: #333;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 37%;
            width: 4px;
            height: 20px;
            border-radius: 2px;
            background-color: var(--theme);
          }
        }
      }
    }
    .card {
      padding-left: 12px;
      color: #666;
      .rule {
        dd {
          .mixinFlex(flex-start;);
          .dian {
            position: relative;
            padding-left: 16px;
            &::before {
              content: "●";
              position: absolute;
              top: 0;
              left: 0;
              color: #666;
            }
          }
          .switch-screen {
            color: var(--theme);
          }
          .certificate {
            .mixinFlex(flex-start);
            img {
              width: 202px;
              height: 120px;
            }
            span {
              align-self: flex-end;
            }
          }
        }
        .tips {
          position: relative;
          display: inline-block;
          margin-left: 100px;
          padding-left: 10px;
          &::before {
            content: "*";
            position: absolute;
            top: 0;
            left: 0;
            color: #e41c1c;
          }
        }
        // list-style-type: disc;
      }
      .history {
        .item {
          display: inline-block;
          width: 358px;
          padding: 20px 25px;
          font-size: 14px;
          border-radius: 4px;
          margin-bottom: 22px;
          margin-right: 22px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          background: #f5f5f6;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .info {
            position: relative;
            p {
              margin: 0;
              line-height: 2.2;
            }
            .status {
              position: absolute;
              top: 3px;
              right: -20px;
              min-width: 100px;
              text-align: center;
            }
            .status-8,
            .status-9 {
              color: var(--theme);
            }
            .status-1 {
              color: #f09959;
            }
            .status-2 {
              color: #f65464;
            }
            .score {
              position: absolute;
              right: 0;
              bottom: 0;
              font-size: 30px;
              font-weight: 700;
              // color: var(--theme);
              letter-spacing: -0.04em;
              span {
                font-size: 14px;
              }
            }
            .pass {
              color: var(--theme);
            }
          }
          .signature-icon {
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            border-radius: 0 0 75% 0;
            background-color: #f2994a;
            &::before {
              content: "签";
              position: absolute;
              top: 10%;
              left: 20%;
              font-size: 14px;
              color: #fff;
            }
          }
          .not-valid {
            position: absolute;
            top: 0;
            right: 0;
            width: 60px;
            height: 60px;
            background: url("~@/assets/image/not-valid.png") no-repeat;
            background-size: cover;
          }
          // .status {
          //   position: absolute;
          //   right: 25px;
          //   top: 20px;
          //   width: 100px;
          //   height: 25px;
          //   line-height: 25px;
          //   text-align: center;
          //   font-size: 12px;
          //   // color: #666;
          //   // transform: rotate(45deg);
          //   &.done {
          //     background-color: #1ab700;
          //   }
          //   &.undone {
          //     background-color: #f65464;
          //   }
          //   &.wait {
          //     background-color: #f09959;
          //   }
          // }
        }
      }
    }
  }
}

.mod-3 {
  padding: 20px 20px 0;
  .content {
    .mixinWrap();
    padding: 0 15px 15px;
    background-color: #fff;
    border-radius: 6px;
    h5 {
      position: relative;
      margin: 0;
      padding: 20px 12px;
      font-size: 20px;
      font-weight: 550;
      color: #333;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 37%;
        width: 4px;
        height: 20px;
        border-radius: 2px;
        background-color: var(--theme);
      }
    }
    .retake-wrap {
      .mixinFlex(flex-start, center);
      padding: 0 15px;
      flex-wrap: wrap;
      color: #999;
    }
    .retake-item {
      position: relative;
      width: 358px;
      margin: 0 22px 22px 0;
      padding: 10px 15px;
      background-color: #f5f5f5;
      border-radius: 8px;
      font-size: 14px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .name {
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #333;
      }
      .start,
      .end {
        line-height: 30px;
      }
      .action {
        position: absolute;
        top: 45%;
        right: 15px;
        font-size: 16px;
      }
    }
  }
}
</style>
